import i18n from '../lang/index'
import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login.vue";
import help from "@/assets/help.js";
import { Message } from "element-ui";

Vue.use(VueRouter);

const routes = [{
        path: "/",
        redirect: "/login",
    },
    {
        path: "/login",
        component: Login,
        meta: {
            title: i18n.t('router.index.400456-0'),
            needAuth: false,
        },
    },

    
 
    {
        path: "/manage",
        component: () =>
            import ("@/views/Manage.vue"),
        redirect: "/manage/shop/Info",
        children: [{
                path: "shop",
                component: () =>
                    import ("@/views/manage/ManageMain.vue"),
                children: [{
                    path: "info",
                    component: () =>
                        import ("@/views/manage/shop/Info.vue"),
                    meta: {
                        title: i18n.t('router.index.400456-1'),
                        needAuth: true,
                    },
                }, ],
            },
            {
                path: "goods",
                component: () =>
                    import ("@/views/manage/ManageMain.vue"),
                children: [{
                        path: "publish",
                        component: () =>
                            import ("@/views/manage/goods/Publish.vue"),
                        meta: {
                            title: i18n.t('router.index.400456-2'),
                            needAuth: true,
                        },
                    },
                    {
                        path: "list",
                        component: () =>
                            import ("@/views/manage/goods/List.vue"),
                        meta: {
                            title: i18n.t('router.index.400456-3'),
                            needAuth: true,
                            keepAlive: true
                        },
                    },
                ],
            },
            {
                path: "order",
                component: () =>
                    import ("@/views/manage/ManageMain.vue"),
                children: [{
                        path: "arrList",
                        component: () =>
                            import ("@/views/manage/order/ArrList.vue"),
                        meta: {
                            title: i18n.t('router.index.400456-4'),
                            needAuth: true,
                        },
                    },
                    {
                        path: "refund",
                        component: () =>
                            import ("@/views/manage/order/Refund.vue"),
                        meta: {
                            title: i18n.t('router.index.400456-5'),
                            needAuth: true,
                        },
                    },
                    {
                        path: "RestockLists",
                        component: () =>
                            import ("@/views/manage/order/RestockLists.vue"),
                        meta: {
                            title: i18n.t('router.index.400456-6'),
                            needAuth: true,
                        },
                    },
                ],
            },
            {
                path: "finance",
                component: () =>
                    import ("@/views/manage/ManageMain.vue"),
                children: [{
                        path: "report",
                        component: () =>
                            import ("@/views/manage/finance/Report.vue"),
                        meta: {
                            title: i18n.t('router.index.400456-7'),
                            needAuth: true,
                        },
                    },
                    {
                        path: "withdraw",
                        component: () =>
                            import ("@/views/manage/finance/Withdraw.vue"),
                        meta: {
                            title: i18n.t('router.index.400456-8'),
                            needAuth: true,
                        },
                    },
                ],
            },
          
        ],
    },

    {
        //这个*路由一定要放在最后
        path: "*",
        component: () =>
            import ("@/views/error/404.vue"),
    },
	{
        //这个*路由一定要放在最后
        path: "/500",
        component: () =>
            import ("@/views/error/500.vue"),
    },
];

const router = new VueRouter({
    routes,
});

// 全局路由守卫
router.beforeEach((to, from, next) => {
    // console.log("文件", to.path);
    document.title = to.meta.title || i18n.t('router.index.400456-9');
    let token = localStorage.getItem("token");
    if (to.meta.needAuth) {
        if (token) {
            next();
        } else {
            Message.info(i18n.t('router.index.400456-10', [to.meta.title]));
            next({
                path: "/login",
                // 跳转时传递参数到登录页面，以便登录后可以跳转到对应页面
                query: {
                    redirect: to.path,
                },
            });
        }
    } else {
        next();
    }
});

export default router;