import http from "@/assets/http.js";
export default {
    //示例1
    example_1(params) {
        return http({
            url: '/example_1',
            method: 'get',
            withToken:false,
            params
        })
    },
    //示例2
    example_2(data) {
        return http({
            url: '/login/loginAccount',
            method: 'post',
            withToken:false,
            data
        })
    },
    /**
     * 用户签到
     * @params_1   参数1说明
     * @params_2   参数2说明
     */
    addSignIn(data) {
        return http({
            url: '/api/common/signIn/addSignIn',
            method: 'post',
            withToken:false,
            data
        })
    },
    /**
     * 获取调查问卷测试题目
     * @params_1   参数1说明
     * @params_2   参数2说明
     */
    getSurveyQuestions(data) {
        return http({
            url: '/api/common/questionnaire/selectQuestionnaireInvestigation',
            method: 'post',
            withToken:false,
            data
        })
    },
    /**
     * 提交问卷调查答案
     * @params_1   参数1说明
     * @params_2   参数2说明
     */
    submitSurveyAnswers(data) {
        return http({
            url: '/api/common/questionnaire/submitQuestionnaireAnswers',
            method: 'post',
            withToken:false,
            data
        })
    }
}