export default {
    "shop.Info.235900-0": "Store Image",
    "shop.Info.235900-1": "Edit Image",
    "shop.Info.235900-2": "Store Name",
    "shop.Info.235900-3": "Store ID",
    "shop.Info.235900-4": "Contact Number",
    "shop.Info.235900-5": "Change",
    "shop.Info.235900-6": "Detailed Address",
    "shop.Info.235900-7": "Legal Representative Name",
    "shop.Info.235900-8": "Legal Representative Document",
    "shop.Info.235900-9": "Business License",
    "shop.Info.235900-10": "ID Card Front",
    "shop.Info.235900-11": "ID Card Back",
    "shop.Info.235900-12": "Hold ID Card",
    "shop.Info.235900-13": "Change Address",
    "shop.Info.235900-14": "Province",
    "shop.Info.235900-15": "City",
    "shop.Info.235900-16": "District",
    "shop.Info.235900-17": "Please enter detailed address",
    "shop.Info.235900-18": "Cancel",
    "shop.Info.235900-19": "Confirm",
    "shop.Info.235900-20": "Change Phone Number",
    "shop.Info.235900-21": "Please enter phone number",
    "shop.Info.235900-22": "Verification Code",
    "shop.Info.235900-23": "Image Upload",
    "shop.Info.235900-24": "Drag and drop file here or",
    "shop.Info.235900-25": "Click to upload",
    "shop.Info.235900-26": "Only upload jpg/png files, max size 500kb",
    "shop.Info.235900-27": "Phone number must be 11 digits",
    "shop.Info.235900-28": "Get Verification Code",
    "shop.Info.235900-29": "Please enter verification code",
    "shop.Info.235900-30": "Please fill in all the data",
    "shop.Info.235900-31": "Address modification successful",
    "shop.Info.235900-32": "Address modification failed",
    "shop.Info.235900-33": "Retry after seconds",
    "goods.Publish.440664-0": "Product Image",
    "goods.Publish.440664-1": "Up to 5 images can be uploaded, the first one is the product thumbnail, and it will be displayed in the list",
    "goods.Publish.440664-2": "Product Name",
    "goods.Publish.440664-3": "Please enter the product name",
    "goods.Publish.440664-4": "Product Category",
    "goods.Publish.440664-5": "Select Zone",
    "goods.Publish.440664-6": "Ordinary Product",
    "goods.Publish.440664-7": "*For products in the No.1 zone, the platform will charge a 15% service fee, and users can get 5 times the gift voucher when purchasing.",
    "goods.Publish.440664-8": "Product Specifications",
    "goods.Publish.440664-9": "Add Specifications",
    "goods.Publish.440664-10": "Please enter the specification name (for example: color, size, weight, etc.)",
    "goods.Publish.440664-11": "Please enter the specification value (for example: red, S size, 5 jin, etc.)",
    "goods.Publish.440664-12": "Add Specification Value",
    "goods.Publish.440664-13": "Generate Specification Table",
    "goods.Publish.440664-14": "Specification",
    "goods.Publish.440664-15": "Price",
    "goods.Publish.440664-16": "Market Price",
    "goods.Publish.440664-17": "Stock",
    "goods.Publish.440664-18": "Shipping Method",
    "goods.Publish.440664-19": "Logistics",
    "goods.Publish.440664-20": "Pickup",
    "goods.Publish.440664-21": "Delivery",
    "goods.Publish.440664-22": "Sale Status",
    "goods.Publish.440664-23": "Official Sale",
    "goods.Publish.440664-24": "Not on Sale",
    "goods.Publish.440664-25": "Product Details",
    "goods.Publish.440664-26": "Please enter product details",
    "goods.Publish.440664-27": "Publish",
    "goods.Publish.440664-28": "Reset",
    "goods.Publish.440664-29": "Color",
    "goods.Publish.440664-30": "Size",
    "goods.Publish.440664-31": "Unit Price",
    "goods.Publish.440664-32": "Please enter",
    "goods.Publish.440664-33": "Product name cannot be empty",
    "goods.Publish.440664-34": "Please select",
    "goods.Publish.440664-35": "Add up to five images",
    "goods.Publish.440664-36": "Uploaded avatar images can only be JPG/PNG format!",
    "goods.Publish.440664-37": "Uploaded avatar images cannot exceed 2MB!",
    "goods.Publish.440664-38": "Only 5 images can be uploaded",
    "goods.Publish.440664-39": "Only two specification names can be set",
    "goods.Publish.440664-40": "Specification name cannot be empty, please regenerate the table",
    "goods.Publish.440664-41": "Specification price format error",
    "goods.Publish.440664-42": "Stock can only be a number",
    "goods.Publish.440664-43": "Market price format error",
    "goods.Publish.440664-44": "Please generate a specification table for the product specifications!",
    "goods.Publish.440664-45": "Please fill in the content correctly according to the prompt information!",
    "goods.Publish.440664-46": "Product Name",
    "goods.List.516095-0": "Product Status",
    "goods.List.516095-1": "Please select the product status",
    "goods.List.516095-2": "On Sale",
    "goods.List.516095-3": "Please select the on sale status",
    "goods.List.516095-4": "Product Name",
    "goods.List.516095-5": "Please enter the product name",
    "goods.List.516095-6": "Creation Time",
    "goods.List.516095-7": "To",
    "goods.List.516095-8": "Start Date",
    "goods.List.516095-9": "End Date",
    "goods.List.516095-10": "Product Category",
    "goods.List.516095-11": "Please select",
    "goods.List.516095-12": "Search",
    "goods.List.516095-13": "Reset",
    "goods.List.516095-14": "No.",
    "goods.List.516095-15": "Product ID",
    "goods.List.516095-16": "Product Image",
    "goods.List.516095-17": "Product Category",
    "goods.List.516095-18": "Regular Product",
    "goods.List.516095-19": "Shared Product",
    "goods.List.516095-20": "Inventory",
    "goods.List.516095-21": "On Sale",
    "goods.List.516095-22": "Not on Sale",
    "goods.List.516095-23": "Update Time",
    "goods.List.516095-24": "Sales Volume",
    "goods.List.516095-25": "Last Rejection Reason",
    "goods.List.516095-26": "Action",
    "goods.List.516095-27": "Delivery Method",
    "goods.List.516095-28": "List",
    "goods.List.516095-29": "Edit",
    "goods.List.516095-30": "Delete",
    "goods.List.516095-31": "View Reviews",
    "goods.List.516095-32": "View",
    "goods.List.516095-33": "Remove from Sale",
    "goods.List.516095-34": "Set Inventory",
    "goods.List.516095-35": "Pending Review",
    "goods.List.516095-36": "Edit Again",
    "goods.List.516095-37": "Delete Product",
    "goods.List.516095-38": "Start Sale",
    "goods.List.516095-39": "Stop Sale",
    "goods.List.516095-40": "Specifications",
    "goods.List.516095-41": "Confirm Changes",
    "goods.List.516095-42": "Cancel",
    "goods.List.516095-43": "View Product Reviews",
    "goods.List.516095-44": "Product Name:",
    "goods.List.516095-45": "Review ID",
    "goods.List.516095-46": "Review Content",
    "goods.List.516095-47": "Review Images",
    "goods.List.516095-48": "Review Rating",
    "goods.List.516095-49": "Username",
    "goods.List.516095-50": "Product Information",
    "goods.List.516095-51": "Name",
    "goods.List.516095-52": "Category",
    "goods.List.516095-53": "Zone",
    "goods.List.516095-54": "Zone 1 Product",
    "goods.List.516095-55": "Price",
    "goods.List.516095-56": "Market Price",
    "goods.List.516095-57": "Delivery",
    "goods.List.516095-58": "Logistics",
    "goods.List.516095-59": "Pickup",
    "goods.List.516095-60": "Officially on Sale",
    "goods.List.516095-61": "Not on Sale Yet",
    "goods.List.516095-62": "Details",
    "goods.List.516095-63": "List All",
    "goods.List.516095-64": "Remove All",
    "goods.List.516095-65": "Please select a delivery method",
    "goods.List.516095-66": "You must select at least one delivery method!",
    "goods.List.516095-67": "Confirm",
    "goods.List.516095-68": "All Products",
    "goods.List.516095-69": "Failed",
    "goods.List.516095-70": "Reason",
    "goods.List.516095-71": "Liability Amount",
    "goods.List.516095-72": "CNY",
    "goods.List.516095-73": "Remarks",
    "order.ArrList.924216-0": "Payment Status",
    "order.ArrList.924216-1": "Please select the payment status",
    "order.ArrList.924216-2": "Order Number",
    "order.ArrList.924216-3": "Please enter the order number",
    "order.ArrList.924216-4": "Order Status",
    "order.ArrList.924216-5": "Please select the order status",
    "order.ArrList.924216-6": "Recipient Name",
    "order.ArrList.924216-7": "Please enter the recipient name",
    "order.ArrList.924216-8": "Recipient Phone Number",
    "order.ArrList.924216-9": "Please enter the recipient phone number",
    "order.ArrList.924216-10": "Product Name",
    "order.ArrList.924216-11": "Please enter the product name",
    "order.ArrList.924216-12": "Order Time",
    "order.ArrList.924216-13": "To",
    "order.ArrList.924216-14": "Start Date",
    "order.ArrList.924216-15": "End Date",
    "order.ArrList.924216-16": "Confirmation Time",
    "order.ArrList.924216-17": "Delivery Time",
    "order.ArrList.924216-18": "Order Type",
    "order.ArrList.924216-19": "Please select the order type",
    "order.ArrList.924216-20": "Pickup Order",
    "order.ArrList.924216-21": "Logistics Order",
    "order.ArrList.924216-22": "Order Category",
    "order.ArrList.924216-23": "Please select the order category",
    "order.ArrList.924216-24": "Shared Order",
    "order.ArrList.924216-25": "Regular Order",
    "order.ArrList.924216-26": "Search",
    "order.ArrList.924216-27": "Reset",
    "order.ArrList.924216-28": "Export to Excel",
    "order.ArrList.924216-29": "Member ID",
    "order.ArrList.924216-30": "Quantity",
    "order.ArrList.924216-31": "Unit Price",
    "order.ArrList.924216-32": "Subtotal",
    "order.ArrList.924216-33": "Recipient Mobile Number",
    "order.ArrList.924216-34": "Shipping Address",
    "order.ArrList.924216-35": "Order Total",
    "order.ArrList.924216-36": "Rural Revitalization Voucher Deduction",
    "order.ArrList.924216-37": "One County, One Product Voucher Deduction",
    "order.ArrList.924216-38": "Amount Paid",
    "order.ArrList.924216-39": "Platform Commission",
    "order.ArrList.924216-40": "Merchant Profit",
    "order.ArrList.924216-41": "Paid Offline",
    "order.ArrList.924216-42": "Apply for After-sales",
    "order.ArrList.924216-43": "Payment Method",
    "order.ArrList.924216-44": "Remarks",
    "order.ArrList.924216-45": "Pending Pickup",
    "order.ArrList.924216-46": "Cash Payment Time",
    "order.ArrList.924216-47": "Voucher Payment Time",
    "order.ArrList.924216-48": "Action",
    "order.ArrList.924216-49": "For pickup orders, please verify the order on the mobile end",
    "order.ArrList.924216-50": "View",
    "order.ArrList.924216-51": "View Logistics",
    "order.ArrList.924216-52": "Ship",
    "order.ArrList.924216-53": "Modify Recipient Information",
    "order.ArrList.924216-54": "After completing the logistics company information, please",
    "order.ArrList.924216-55": "Add Tracking Number",
    "order.ArrList.924216-56": "Logistics Company Name",
    "order.ArrList.924216-57": "Please select",
    "order.ArrList.924216-58": "Tracking Number",
    "order.ArrList.924216-59": "Tracking number cannot be empty",
    "order.ArrList.924216-60": "Delete",
    "order.ArrList.924216-61": "Cancel",
    "order.ArrList.924216-62": "Confirm",
    "order.ArrList.924216-63": "Modify Logistics",
    "order.ArrList.924216-64": "Please enter the tracking number",
    "order.ArrList.924216-65": "View Details",
    "order.ArrList.924216-66": "Modify Recipient Information",
    "order.ArrList.924216-67": "Recipient: ",
    "order.ArrList.924216-68": "Contact Number: ",
    "order.ArrList.924216-69": "Province/City/District: ",
    "order.ArrList.924216-70": "Please select province",
    "order.ArrList.924216-71": "Please select city",
    "order.ArrList.924216-72": "Please select district",
    "order.ArrList.924216-73": "Detailed Address: ",
    "order.ArrList.924216-74": "Cancel",
    "order.ArrList.924216-75": "Confirm",
    "order.ArrList.924216-76": "Please select a logistics company",
    "order.ArrList.924216-77": "Copied successfully",
    "order.ArrList.924216-78": "Please add a tracking number",
    "order.RestockLists.182807-0": "Order Number:",
    "order.RestockLists.182807-1": "Please enter the order number",
    "order.RestockLists.182807-2": "Product Name:",
    "order.RestockLists.182807-3": "Please enter the product name",
    "order.RestockLists.182807-4": "Order Status:",
    "order.RestockLists.182807-5": "Please select the order status",
    "order.RestockLists.182807-6": "Pending Payment",
    "order.RestockLists.182807-7": "Pending Shipment",
    "order.RestockLists.182807-8": "Pending Warehouse Entry",
    "order.RestockLists.182807-9": "Warehouse Entry Complete",
    "order.RestockLists.182807-10": "Date Selection",
    "order.RestockLists.182807-11": "Please select a date",
    "order.RestockLists.182807-12": "Today",
    "order.RestockLists.182807-13": "Last 7 Days",
    "order.RestockLists.182807-14": "Last Month",
    "order.RestockLists.182807-15": "Last 3 Months",
    "order.RestockLists.182807-16": "Last 6 Months",
    "order.RestockLists.182807-17": "Search",
    "order.RestockLists.182807-18": "Refresh",
    "order.RestockLists.182807-19": "Export to Excel",
    "order.RestockLists.182807-20": "Order Number",
    "order.RestockLists.182807-21": "Order Amount",
    "order.RestockLists.182807-22": "Unit Price",
    "order.RestockLists.182807-23": "Product Name",
    "order.RestockLists.182807-24": "Specification",
    "order.RestockLists.182807-25": "Quantity",
    "order.RestockLists.182807-26": "Order Status",
    "order.RestockLists.182807-27": "Order Time",
    "order.RestockLists.182807-28": "Logistics Company",
    "order.RestockLists.182807-29": "Waybill Number",
    "order.RestockLists.182807-30": "Confirmation Time",
    "order.RestockLists.182807-31": "Action",
    "order.RestockLists.182807-32": "View",
    "order.RestockLists.182807-33": "Confirm Warehouse Entry",
    "order.RestockLists.182807-34": "View Details",
    "order.RestockLists.182807-35": "Basic Information",
    "order.RestockLists.182807-36": "Order Status:",
    "order.RestockLists.182807-37": "Order Time:",
    "order.RestockLists.182807-38": "Logistics Information:",
    "order.RestockLists.182807-39": "View Logistics",
    "order.RestockLists.182807-40": "Product Information",
    "order.RestockLists.182807-41": "Product ID",
    "order.RestockLists.182807-42": "Price",
    "order.RestockLists.182807-43": "Subtotal",
    "order.RestockLists.182807-44": "Logistics Information",
    "order.RestockLists.182807-45": "Logistics Company:",
    "order.RestockLists.182807-46": "Waybill Number:",
    "order.RestockLists.182807-47": "Logistics Status:",
    "order.RestockLists.182807-48": "Logistics Details:",
    "order.RestockLists.182807-49": "Failed to retrieve manufacturer orders",
    "order.RestockLists.182807-50": "Do you want to warehouse this product?",
    "order.RestockLists.182807-51": "Confirm",
    "order.RestockLists.182807-52": "Cancel",
    "order.RestockLists.182807-53": "Warehouse entry successful!",
    "order.RestockLists.182807-54": "Warehouse entry canceled",
    "order.Refund.396382-0": "Order Number",
    "order.Refund.396382-1": "Please enter the order number",
    "order.Refund.396382-2": "Product Name",
    "order.Refund.396382-3": "Please enter the product name",
    "order.Refund.396382-4": "Status",
    "order.Refund.396382-5": "Please select the status",
    "order.Refund.396382-6": "Contact Phone",
    "order.Refund.396382-7": "Please enter the contact phone",
    "order.Refund.396382-8": "Order Type",
    "order.Refund.396382-9": "Please select the order type",
    "order.Refund.396382-10": "Pickup Order",
    "order.Refund.396382-11": "Logistics Order",
    "order.Refund.396382-12": "Order Category",
    "order.Refund.396382-13": "Please select the order category",
    "order.Refund.396382-14": "Regular Order",
    "order.Refund.396382-15": "Shared Order",
    "order.Refund.396382-16": "Search",
    "order.Refund.396382-17": "Reset",
    "order.Refund.396382-18": "Member ID",
    "order.Refund.396382-19": "Refund Amount",
    "order.Refund.396382-20": "Refund Reason",
    "order.Refund.396382-21": "Refund Time",
    "order.Refund.396382-22": "Pending Review",
    "order.Refund.396382-23": "Agree",
    "order.Refund.396382-24": "Disagree",
    "order.Refund.396382-25": "Action",
    "order.Refund.396382-26": "View",
    "order.Refund.396382-27": "Review",
    "order.Refund.396382-28": "Refund Review",
    "order.Refund.396382-29": "Merchant Review Comments",
    "order.Refund.396382-30": "Please enter the merchant review comments",
    "order.Refund.396382-31": "Review Result",
    "order.Refund.396382-32": "Please select",
    "order.Refund.396382-33": "Cancel",
    "order.Refund.396382-34": "Confirm",
    "order.Refund.396382-35": "View Details",
    "order.Refund.396382-36": "Please select the review result",
    "order.Details.493022-0": "Basic Information",
    "order.Details.493022-1": "Order Number:",
    "order.Details.493022-2": "Order Status:",
    "order.Details.493022-3": "Refunding",
    "order.Details.493022-4": "Refund Failed",
    "order.Details.493022-5": "Refund Successful",
    "order.Details.493022-6": "Order Time:",
    "order.Details.493022-7": "Nickname:",
    "order.Details.493022-8": "Payment Time:",
    "order.Details.493022-9": "Payment Method:",
    "order.Details.493022-10": "Recipient Name:",
    "order.Details.493022-11": "Recipient Contact Phone:",
    "order.Details.493022-12": "Shipping Address:",
    "order.Details.493022-13": "Merchant Refusal Reason for Refund:",
    "order.Details.493022-14": "Logistics Information:",
    "order.Details.493022-15": "Edit Logistics",
    "order.Details.493022-16": "Product Information",
    "order.Details.493022-17": "Product ID",
    "order.Details.493022-18": "Product Name",
    "order.Details.493022-19": "Zone Category",
    "order.Details.493022-20": "Price",
    "order.Details.493022-21": "Quantity",
    "order.Details.493022-22": "Specification",
    "order.Details.493022-23": "Subtotal",
    "order.Details.493022-24": "Buyer's Message",
    "order.Details.493022-25": "Product Image",
    "order.Details.493022-26": "Name",
    "order.Details.493022-27": "Category",
    "order.Details.493022-28": "Zone",
    "order.Details.493022-29": "Zone 1 Product",
    "order.Details.493022-30": "Regular Product",
    "order.Details.493022-31": "Inventory",
    "order.Details.493022-32": "Delivery",
    "order.Details.493022-33": "Logistics",
    "order.Details.493022-34": "Pickup",
    "order.Details.493022-35": "Details",
    "order.Details.493022-36": "After completing the logistics company information, please",
    "order.Details.493022-37": "Add Tracking Number",
    "order.Details.493022-38": "Logistics Company Name",
    "order.Details.493022-39": "Please select",
    "order.Details.493022-40": "Tracking Number",
    "order.Details.493022-41": "Tracking number cannot be empty",
    "order.Details.493022-42": "Delete",
    "order.Details.493022-43": "Cancel",
    "order.Details.493022-44": "Confirm",
    "order.Details.493022-45": "Please select a logistics company",
    "order.Details.493022-46": "Please add a tracking number",
    "order.Details.493022-47": "Modification successful",
    "finance.Report.576422-0": "Total Number of Valid Orders",
    "finance.Report.576422-1": "orders",
    "finance.Report.576422-2": "Total Amount of Valid Orders",
    "finance.Report.576422-3": "CNY",
    "finance.Report.576422-4": "Total Settlement Amount",
    "finance.Report.576422-5": "Total Settled Amount",
    "finance.Report.576422-6": "Data Statistics",
    "finance.Report.576422-7": "Total Number of Orders Today",
    "finance.Report.576422-8": "Total Amount of Orders Today",
    "finance.Report.576422-9": "Total Number of Orders This Month",
    "finance.Report.576422-10": "Total Amount of Orders This Month",
    "finance.Withdraw.623086-0": "Available Withdrawable Balance:",
    "finance.Withdraw.623086-1": "Temporary Unavailable Balance:",
    "finance.Withdraw.623086-2": "Enter Withdrawal Amount:",
    "finance.Withdraw.623086-3": "Please enter the amount:",
    "finance.Withdraw.623086-4": "Confirm Withdrawal",
    "finance.Withdraw.623086-5": "1. Withdrawal Opening Hours",
    "finance.Withdraw.623086-6": "Daily",
    "finance.Withdraw.623086-7": "2. Transfer Note: Funds will be credited within T+1 working day. If it falls on a non-working day, it will be postponed to the next working day",
    "finance.Withdraw.623086-8": "Withdrawal Consultation Hotline: 028-8768-3701 Customer Service Hours: 8:30-17:30",
    "finance.Withdraw.623086-9": "Withdrawal amount cannot be empty!",
    "finance.Withdraw.623086-10": "Confirm withdrawal application?",
    "finance.Withdraw.623086-11": "Withdraw",
    "finance.Withdraw.623086-12": "Confirm",
    "finance.Withdraw.623086-13": "Cancel",
    "views.Login.730424-0": "Soole Merchant Backstage Management Center",
    "views.Login.730424-1": "Account:",
    "views.Login.730424-2": "Please enter your account",
    "views.Login.730424-3": "Password:",
    "views.Login.730424-4": "Please enter your password",
    "views.Login.730424-5": "Login",
    "views.Login.730424-6": "Reset",
    "views.Login.730424-7": "Please fill in the information correctly as instructed",
    "views.Manage.792054-0": "Store Management",
    "views.Manage.792054-1": "Store Information",
    "views.Manage.792054-2": "Product Management",
    "views.Manage.792054-3": "Publish Product",
    "views.Manage.792054-4": "Product List",
    "views.Manage.792054-5": "Order Management",
    "views.Manage.792054-6": "All Orders",
    "views.Manage.792054-7": "Refund Orders",
    "views.Manage.792054-8": "Restock Orders",
    "views.Manage.792054-9": "Financial Management",
    "views.Manage.792054-10": "Merchant Financial Report",
    "views.Manage.792054-11": "Withdraw",
    "part.MainHeader.912422-0": "Logout",
    "part.MainHeader.912422-1": "Store Management",
    "part.MainHeader.912422-2": "Store Information",
    "part.MainHeader.912422-3": "Logout successful!",
    "part.MainHeader.680378-0": "Hong Kong China",
    "part.MainHeader.680378-1": "Other regions",
    "part.MainHeader.680378-2": "Mongolia",
    "assets.dict.697759-0": "Under the shelf",
    "assets.dict.697759-1": "On the shelf",
    "assets.dict.697759-2": "Pending review",
    "assets.dict.697759-3": "Not passed the review",
    "assets.dict.697759-4": "One area",
    "assets.dict.697759-5": "Ordinary goods",
    "assets.dict.697759-6": "No postage",
    "assets.dict.697759-7": "Postage",
    "assets.dict.697759-8": "Payment failed",
    "assets.dict.697759-9": "Payment successful",
    "assets.dict.697759-10": "Pending payment",
    "assets.dict.697759-11": "Pending delivery",
    "assets.dict.697759-12": "Pending receipt/shipped",
    "assets.dict.697759-13": "Completed/Unrated",
    "assets.dict.697759-14": "Rated",
    "assets.dict.697759-15": "Refund successful",
    "assets.dict.697759-16": "Apply for refund",
    "assets.dict.697759-17": "WeChat",
    "assets.dict.697759-18": "Alipay",
    "assets.dict.697759-19": "Shopping voucher",
    "assets.dict.697759-20": "Alipay+Shopping voucher",
    "assets.dict.697759-21": "WeChat+Shopping voucher",
    "assets.dict.697759-22": "Payment for goods",
    "assets.dict.697759-23": "Self-pick up",
    "assets.dict.697759-24": "Unreviewed",
    "assets.dict.697759-25": "Agree",
    "assets.dict.697759-26": "Disagree",
    "assets.dict.697759-27": "Delisted",
    "assets.dict.697759-28": "On the shelf",
    "assets.dict.697759-29": "Pending listing",
    "assets.dict.697759-30": "Pending entry",
    "assets.dict.697759-31": "Entered",
    "assets.dict.697759-32": "Opened",
    "assets.dict.697759-33": "Not opened",
    "assets.dict.697759-34": "No record",
    "assets.dict.697759-35": "Picked up",
    "assets.dict.697759-36": "In transit",
    "assets.dict.697759-37": "Delivery",
    "assets.dict.697759-38": "Signed",
    "assets.dict.697759-39": "Refused to sign",
    "assets.dict.697759-40": "Doubtful",
    "assets.dict.697759-41": "Returned",
    "assets.dict.697759-42": "Returned",
    "assets.dict.697759-43": "Transfer",
    "assets.dict.697759-44": "PaymentAsia-Alipay",
	"assets.dict.697759-45": "PaymentAsia-WeChat",
    "router.index.400456-0": "Login",
    "router.index.400456-1": "Shop information",
    "router.index.400456-2": "Publish goods",
    "router.index.400456-3": "Product list",
    "router.index.400456-4": "All orders",
    "router.index.400456-5": "Refund order",
    "router.index.400456-6": "Purchase order",
    "router.index.400456-7": "Merchant financial report",
    "router.index.400456-8": "Withdrawal",
    "router.index.400456-9": "This page does not exist",
    "router.index.400456-10": "Access to {0} page requires authorization, please login first!"
}
