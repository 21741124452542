//api.js
import file from "@/assets/api/file.js";
import user from "@/assets/api/user.js";
import order from "@/assets/api/order.js";
import goods from "@/assets/api/goods.js";
import view from "@/assets/api/view.js";
import signin from "@/assets/api/unit/signin.js";
import common from "@/assets/api/common.js";
export default {
    file,
    user,
    order,
    goods,
    view,
    signin,
    common,
}