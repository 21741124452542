// 过滤表单数据的方法
export function formatSubForm(data) {
    const params = Object.keys(data)
        .filter(
            (key) =>
            data[key] !== null && data[key] !== undefined && data[key] !== ""
        )
        .reduce((acc, key) => ({...acc, [key]: data[key] }), {});
    return params;
}

export const clearForm=(form)=>{
        Object.keys(form).forEach(item=>{
         form[item]=null
       })
    return form
}