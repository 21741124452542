<template>
  <div class="login f-c-c">
    <div class="switchAddress">
      <el-button @click="chekLanguage('zh')" size="mini" type="primary"
        >{{ $t('part.MainHeader.680378-0') }}</el-button
      >
      <el-button @click="chekLanguage('mn')" size="mini" type="primary"
      >{{ $t('part.MainHeader.680378-2') }}</el-button
      >
      <el-button @click="chekLanguage('en')" size="mini" type="primary"
        >{{ $t('part.MainHeader.680378-1') }}</el-button
      >
    </div>
    <div class="wrap bounce-in">
      <div class="head f-c-c">{{ $t('views.Login.730424-0') }}</div>
      <el-form
        class="body"
        ref="form"
        :model="form"
        :rules="rules"
        label-width="auto"
      >
        <el-form-item :label="$t('views.Login.730424-1')" prop="phone">
          <el-input v-model="form.phone" :placeholder="$t('views.Login.730424-2')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('views.Login.730424-3')" prop="pwd">
          <el-input
            v-model="form.pwd"
            type="password"
            :placeholder="$t('views.Login.730424-4')"
          ></el-input>
        </el-form-item>
        <el-form-item class="f-c-c">
          <el-button type="primary" @click="onSubmit">{{ $t('views.Login.730424-5') }}</el-button>
          <el-button @click="onReset">{{ $t('views.Login.730424-6') }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      form: {
        phone: "",
        pwd: "",
      },
      rules: {
        phone: [
          {
            required: true,
            message: this.$t('views.Login.730424-2'),
            trigger: "blur",
          },
        ],
        pwd: [
          {
            required: true,
            message: this.$t('views.Login.730424-4'),
            trigger: "blur",
          },
        ],
      },
    };
  },


  mounted(){
    localStorage.setItem("switchTab", 'login');
  },

  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$api.user.loginIn(this.form).then((res) => {
            let { data, message, success } = res;
            if (success) {
              this.$store.commit("storeLoginInfo", data);
              localStorage.setItem("store", JSON.stringify(this.$store.state));
              localStorage.setItem("token", data.token);
              localStorage.removeItem('switchTab')
              this.$router.push("/manage/shop/info");
            } else {
              this.$message.error(message);
            }
          });
        } else {
          this.$message.warning(this.$t('views.Login.730424-7'));
        }
      });
    },

    onReset() {
      this.$refs.form.resetFields();
    },

    chekLanguage(val) {
      localStorage.setItem("lang", val);
      this.$i18n.locale = val;
      this.$router.push("/login");
      location.reload();
    },

  },
};
</script>
<style lang="scss" scoped>
.login {
  height: 100vh;
  background: url("../assets/img/bg/login.jpg") no-repeat 0 0/100% 100% #eee;
  position: relative;

  .switchAddress{
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.wrap {
  width: 500px;
  border-radius: 20px;
  overflow: hidden;
  background: #fff;
  text-align: center;

  .head {
    color: $color-theme;
    font-weight: 600;
    font-size: 30px;
    height: 130px;
    background: #eee;
  }

  .body {
    padding: 50px 70px;
  }

}
</style>
