<template>
  <div id="editor"></div>
</template>

<script>
//wangEditor官网地址： https://www.wangeditor.com/
import config from "/src/assets/config.js";
import E from "wangeditor";
export default {
  props: {
    //编辑器高度：单位px
    height: {
      type: [String, Number],
      default: 500,
    },
    placeholder: {
      type: String,
      default: "请输入内容",
    },
  },
  data() {
    return {
      editor: null,
    };
  },
  created() {},
  mounted() {
    const editor = new E("#editor");
    editor.config.uploadImgServer =
      config.baseUrl + "/api/common/fileInfo/uploadImg";
    editor.config.uploadFileName = "file";
    editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
    editor.config.uploadImgTimeout = 20000;
    editor.config.pasteFilterStyle = false;
    editor.config.placeholder=this.$t('goods.Publish.440664-26')

    // 编辑器的事件，每次改变会获取其html内容（html内容是带标签的）
    // editor.config.uploadImgHeaders = {
    //   Authorization: help.cookie.getItem("token"),
    // };
    // this.editor.config.height = this.height;
    // this.editor.config.placeholder = this.placeholder;
    // this.editor.config.onchange = this.onChange;
    // this.editor.config.onblur=this.onBlur;
    // this.editor.config.onfocus=this.onFocus;
    // this.editor.config.showLinkImg = false;  //隐藏网络图片

    // #editor>.w-e-text-container>#w-e-text{
    //   display:grid;
    // }
    // #editor>.w-e-text-container>#w-e-text>.w-e-text>p{
    // display:grid;
    // }
    // 配置 onchange 回调函数
    // <style  >#editor .w-e-text-container *{display: block;}</style>
    editor.config.onchange = function (newHtml) {
      setTimeout(function () {
        document.querySelectorAll("#editor .w-e-text-container p").forEach(function (box) {
            box.style.display = "block";
            box.style.maxWidth = "100%";
          });

        document.querySelectorAll("#editor .w-e-text-container div").forEach(function (box) {
            box.style.display = "block";
            box.style.maxWidth = "100%";
          });

        if (document.querySelectorAll("#editor .w-e-text-container img") != null) {
          document.querySelectorAll("#editor .w-e-text-container img").forEach(function (box) {
              box.style.display = "block";
              box.style.maxWidth = "100%";
            });
        }

         document.querySelectorAll("#editor .placeholder").forEach(function (box) {
            box.style.display = "none";
          });
        


      }, 500);
    };

    // 配置触发 onchange 的时间频率，默认为 200ms
    editor.config.onchangeTimeout = 500; // 修改为 500ms

    editor.create();

    editor.config.uploadImgHooks = {
      // 上传图片之前
      before: function (xhr) {
        console.log(xhr);

        // 可阻止图片上传
        // return {
        //     prevent: true,
        //     msg: '需要提示给用户的错误信息'
        // }
      },
      // 图片上传并返回了结果，图片插入已成功
      success: function (xhr) {
        console.log("success", xhr);
      },
      // 图片上传并返回了结果，但图片插入时出错了
      fail: function (xhr, editor, resData) {
        console.log("fail", resData);
      },
      // 上传图片出错，一般为 http 请求的错误
      error: function (xhr, editor, resData) {
        console.log("error", xhr, resData);
      },
      // 上传图片超时
      timeout: function (xhr) {
        console.log("timeout");
      },
      // 图片上传并返回了结果，想要自己把图片插入到编辑器中
      // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
      customInsert: function (insertImgFn, result) {
        // result 即服务端返回的接口
        console.log("customInsert", result);

        // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
        insertImgFn(result.data.fileUrl);
      },
    };

    this.editor = editor;
    // 获取 html
  },
  methods: {
    //设置文本信息
    setHtml(e) {
      this.editor.txt.append(e);
      console.log("设置文本信息", e);

    },
    getHtml() {
      console.log("商品详情信息", this.editor.txt.html());

      return this.editor.txt.html();
    },
  },
};
</script>

<style  >
#editor .w-e-text-container * {
  display: block;
}
</style>