<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    // example: {
    //   type: String,
    //   default: "example",
    //   required: true,
    //   validator(val) {
    //     return ["example", "example_1", "example_2"].includes(val);
    //   },
    // },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {
    // name:{
    //   handler(newVal,oldVal){
    //   },
    //   immediate: true,  //立即监听
    //   deep: true   //深度监听对象属性，
    //  }
  },
  created() {
    let store = JSON.parse(localStorage.getItem("store"));
    if (store) {
      this.$store.replaceState(Object.assign({}, this.$store.state, store));
      // console.log("user", store.user);
      this.$store.commit("save", {
        path: "currMenuPaths",
        data: store.currMenuPaths,
      });
    }
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
// 去掉表格单元格边框
.customer-table {
  .el-table .cell {
    padding: 0px 0 !important;
  }
}
body .el-table th.gutter {
  display: table-cell !important;
} //表格对齐
</style>