
// 公共配置文件
const baseUrls = {
    // development:'http://hktestslbapi.sootoken.com.cn',
    // production: 'http://hktestslbapi.sootoken.com.cn',
    // test: 'http://hktestslbapi.sootoken.com.cn',


    // development:'http://192.168.9.143:1027',
    // production: 'http://192.168.9.143:1027',
    // test: 'http://192.168.9.143:1027',

    development:'http://hkslbapi.mysoule.com',
    production: 'http://hkslbapi.mysoule.com',
    test: 'http://hkslbapi.mysoule.com',

}
export default {
    baseUrl: baseUrls[process.env.NODE_ENV],//axios请求的网址前缀，production”（生产环境）“development”（开发环境）
    showLoadDelay: 500, //单位：毫秒，在多少毫秒后未获取到服务端数据，则显示加载中状态，否则不显示（防止加载状态闪烁屏幕）
    axiosTimeOut: 20000, //axios timeout超时设置，默认为0
}
