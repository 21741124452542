<template>
  <div class="base-echarts" ref="echarts" :style="cssText"></div>
</template>
<script>
// import * as echarts from "echarts";
export default {
  props: {
    //主题背景色
    theme: {
      type: String,
      default: "light",
      validator(val) {
        return ["light", "dark"].includes(val);
      },
    },
    cssText: {
      type: Object,
      default() {
        return {
          background: "#eee",
          height: "300px",
          width: "400px",
        };
      },
    },
    options: {
      type: Object,
      default() {
        return {
          title: {
            text: "ECharts 示例",
          },
          tooltip: {},
          legend: {
            data: ["销量"],
          },
          xAxis: {
            data: ["衬衫", "羊毛衫", "雪纺衫", "裤子", "高跟鞋", "袜子"],
          },
          yAxis: {},
          series: [
            {
              name: "销量",
              type: "bar",
              data: [5, 20, 36, 10, 10, 20],
            },
          ],
        };
      },
    },
  },
  data() {
    return {
      echart: {},
    };
  },
  methods: {
    myEcharts() {
      this.echart = echarts.init(this.$refs.echarts, this.theme);
      this.echart.setOption(this.options);
      window.addEventListener("resize", () => {
        this.echart.resize();
      });
    },
  },
  mounted() {
    this.myEcharts();
  },
};
</script>
<style lang="scss" scoped></style>
